import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import recruitmentImg from "../assets/images/careerfair/recruit-illustration.png";
import banner from "../assets/images/careerfair/cf_banner.png";
import GoldPlusPartners from "../partners/GoldPlusPartners";
import PopupCard from '../components/PopupCard';
import { useEffect, useState } from 'react';
import React from 'react';

export default function CFAbout() {
  let [goldPlusPartners, setGoldPlusPartners] = useState([]);
  let [clickedPartner, setClickedPartner] = useState({});
  let [showPopup, setShowPopup] = useState(false);

  const handleShowPopup = (partner) => {
    setClickedPartner(partner);
    setShowPopup(true);
  }
  const handleClosePopup = () => setShowPopup(false);

  useEffect(() => {
    fetch("/api/partners")
        .then(response => response.json())
        .then(data => {
            setGoldPlusPartners(data['gold+_partners']  // For the FCF 2024 season, use Gold+ in place of Gold as the tier has been split
            .map(company => {
               return ({
                   name: company.name,
                   link: company.link,
                   logo: company.logo,
                   description: company.description,
                   career_fairs: company.career_fairs,
                   events: company.events,
                   industry: company.industry
               })
            }));
        });
    /* document.getElementById('banner').play(); */  // uncomment for video banners
  }, []);

  return (
    <div id="content">
      {/* <video id="banner" width="100%" height="100%" loop muted>
          <source src={banner} type="video/mp4" />
      </video> */}
      <Image className="banner" fluid src={banner}></Image>
      {summary()}
      <Container>
        <div className="career-fair-content">
          {partners({goldPlusPartners, handleShowPopup})}
          {contact()}
        </div>
      </Container>
      <PopupCard show={showPopup} handleClose={handleClosePopup} partner={clickedPartner} />
    </div>
  );
}

function summary() {
  return (
    <Container>
      <div className="career-fair-content">
        <Row>
          <Col sm={12} md={8}>
            <div className="about-cf-box">
              <h3>About the Career Fair</h3>
              <p>
                Looking to land your dream job? This year, meet top notch
                employers and learn about cutting edge industries at YNCN’s
                Fall Career Fair 2024!
              </p>
              <br />
              <p>
                Whether you’re a first year student looking for a{" "}
                <b>summer internship</b>, a fourth year trying to land your
                dream <b>full time job</b>, or hunting for a <b>PEY position</b>
                , our career fair has a company for you. Check out the list of
                attending companies to learn more about who’s right for you and
                what they're looking for!
              </p>
              <br />
              <p>
                Join us on October 4, 2024 at the Carlu,
                and get ready to connect with many
                employers spanning across a wide variety of industries who are
                looking for students like you!
              </p>
              <br />
              <p>
                Fix up your resume and perfect your pitch, because You're Next!
              </p>
              <br></br>
            </div>
          </Col>
          <Col sm={12} md={4}>
            <div className="details-box">
              <div className="details-text">
                <h3>Details</h3>
                <p>
                  <i className="fa fa-clock"></i> Friday Oct 4, 2024
                </p>
                <p>
                  <i className="fa fa-map-marker-alt"></i> The Carlu - 444 Yonge St #7
                </p>
              </div>
              <div className="details-buttons">
                <a
                  className="link-button"
                  href="https://forms.yourenext.ca/"
                >
                  Register
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
}


function partners({goldPlusPartners, handleShowPopup}) {
  return (
    <div>
      <h1>Gold+ Partners</h1>
      <Row>
          <GoldPlusPartners goldPlusPartners={goldPlusPartners} handleShowPopup={handleShowPopup}/>
      </Row>
      <div className="see-all-companies">
        <a
          class="link-button-clear"
          href="/cf/companies"
        >
          See All Companies
        </a>
      </div>
  </div>
  );
}

function contact() {
  return (
    <Row className="info-row">
      <Col xs={12} md={6}>
        <h3>Are You a Recruiter and Want to Attend?</h3>
        <p> If you’re looking to hire, book a booth with us!</p>
        <div class="info-buttons">
          <div>
            <a
              class="link-button-clear"
              href="/contact"
            >
              Contact Us
            </a>
          </div>
        </div>
      </Col>
      <Col xs={12} md={6}>
        <img src={recruitmentImg} alt="2 drawn people talking while laughing" />
      </Col>
    </Row>
  );
}