import React from "react";
import LogoGrid from "../components/LogoGrid";
import Col from "react-bootstrap/Col";
import "./Partners.css";

export default class GoldPlusPartners extends React.Component {
    render() {
        return (
            <Col xs={12}>
                <LogoGrid 
                    logos={this.props.goldPlusPartners} 
                    tier='Gold+'
                    handleShowPopup={this.props.handleShowPopup}
                />
            </Col>
        );
      }
}
